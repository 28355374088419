import React, { useEffect, useState } from "react";
import "./mainPanelFooter.module.css";
import { ReportCart, ReportCartStateEnum } from "compass-commons";
import { BehaviorSubject } from "rxjs";
import { Button } from "dms-lib";
import ReportCartService from "../../../services/ReportCartService";
import ReportCartUpdateError from "../../../errors/ReportCartUpdateError";
import ActiveReportCartNotExistsError from "../../../errors/ActiveReportCartNotExistsError";
import { useStateContext } from "../../../contexts/StateContext";
import StateService from "../../../services/StateService";
import { createReportCart } from "../../../utils/Util";

interface MainPanelFooterProps {
  reportCartSubject: BehaviorSubject<ReportCart>;
}

const MainPanelFooter = (props: MainPanelFooterProps): JSX.Element => {
  const { reportCartSubject } = props;
  const [finishAvailable, setFinishAvailable] = useState(false);
  const [reportCart, setReportCart] = useState(reportCartSubject.getValue());
  const stateService: StateService = useStateContext();
  const footerMessageSubject = stateService.finalizeReportCartMessage;

  useEffect(() => {
    const subscription = reportCartSubject.subscribe((value) => {
      setReportCart(value);
      if (value != null && value.elementList.length > 0) {
        setFinishAvailable(true);
      } else {
        setFinishAvailable(false);
      }
    });

    return function cleanup() {
      subscription.unsubscribe();
    };
  }, [reportCartSubject, finishAvailable]);

  const handleFinish = () => {
    reportCart.status = ReportCartStateEnum.ARCHIVED;
    ReportCartService.updateCart(reportCart)
      .then((res) => {
        if (res && res.status === ReportCartStateEnum.ARCHIVED) {
          createReportCart()
            .then((cart) => {
              if (cart) {
                reportCartSubject.next(cart);
                footerMessageSubject.next(true);
              }
            })
            .catch((error) => {
              throw new ActiveReportCartNotExistsError(error);
            });
        } else {
          throw new ReportCartUpdateError(ReportCartStateEnum.ARCHIVED);
        }
      })
      .catch((error) => {
        throw new ReportCartUpdateError(error);
      });
  };
  return (
    <div className="main-panel-footer-div">
      <Button
        color="primary"
        variant="contained"
        disabled={!finishAvailable}
        onClick={handleFinish}
      >
        Finish
      </Button>
    </div>
  );
};

export default MainPanelFooter;
