import { ReportCart } from "compass-commons";

export default class ReportFileRequestDto {
  reportCartId: string;

  constructor(reportCart?: ReportCart) {
    if (reportCart) {
      this.reportCartId = reportCart.id;
    } else {
      this.reportCartId = "";
    }
  }
}
