import React, { useState } from "react";
import { useI18n } from "compass-commons";
import { EmailRounded } from "@mui/icons-material";
import { BehaviorSubject } from "rxjs";
import FormDialog from "../../commons/dialog/FormDialog";
import EmailForm from "../../emailForm/EmailForm";
import { ReportEmail } from "../../../models/ReportEmail";
import SideActions from "./SideActions";
import ReportPdfProgress from "../../commons/loadingProgress/ReportPdfProgress";

interface SendProps {
  isPdfSizeValid: () => boolean;
  sendEmailCallback: (emailDto: ReportEmail) => void;
  loadingProgress: BehaviorSubject<number>;
  loading: boolean;
  disabled?: boolean;
}

const Send = ({
  isPdfSizeValid,
  sendEmailCallback,
  loading,
  loadingProgress,
  disabled,
}: SendProps): JSX.Element => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const { t: translate } = useI18n();

  const handleClickOpen = () => {
    if (isPdfSizeValid()) {
      setOpenDialog(true);
    }
  };

  const dialogCallback = (response: boolean) => {
    setOpenDialog(response);

    if (response) {
      setSendEmail(true);
    }
  };

  const emailSentCallback = () => {
    setOpenDialog(false);
    setSendEmail(false);
  };

  const validToSendCallBack = (valid) => {
    setIsValid(valid);
  };

  return (
    <>
      <SideActions
        accessor={<span>Send</span>}
        accessorPosition="right"
        disabled={disabled}
        icon={
          loading ? (
            <ReportPdfProgress progress={loadingProgress} />
          ) : (
            <EmailRounded color="primary" />
          )
        }
        onClick={handleClickOpen}
      />
      <FormDialog
        isDialogOpen={openDialog}
        dialogCallback={dialogCallback}
        title={translate("sendForm.title")}
        confirmDisabled={!isValid}
        content={
          <EmailForm
            sendEmailCallback={sendEmailCallback}
            emailSentCallback={emailSentCallback}
            shouldSendEmail={sendEmail}
            isValidToSend={validToSendCallBack}
          />
        }
        disapprovalText={translate("sendForm.disapprovalText")}
        approvalText={translate("sendForm.approvalText")}
      />
    </>
  );
};

export default Send;
