import * as React from "react";
import { useEffect, useState } from "react";
import { Button } from "dms-lib";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

interface FormDialogProps {
  title?: string;
  content?: string | JSX.Element;
  approvalText?: string;
  disapprovalText?: string;
  isDialogOpen: boolean;
  dialogCallback?: (open: boolean) => void;
  contentHeight?: string;
  confirmDisabled?: boolean;
}

const FormDialog = (props: FormDialogProps): JSX.Element => {
  const {
    content,
    title,
    approvalText,
    disapprovalText,
    isDialogOpen,
    dialogCallback,
    contentHeight,
    confirmDisabled,
  } = props;
  const [isOpen, setIsOpen] = useState(isDialogOpen);

  useEffect(() => {
    setIsOpen(isDialogOpen);
  }, [isDialogOpen]);

  const handleClose = () => {
    setIsOpen(false);
    dialogCallback(false);
  };

  const handleConfirm = () => {
    setIsOpen(false);
    dialogCallback(true);
  };

  return (
    <div>
      <Dialog
        fullWidth
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ paddingBottom: "var(--msi-ui-spacing-m)" }}
        >
          {title}
        </DialogTitle>
        {React.isValidElement(content) ? (
          content
        ) : (
          <DialogContent style={{ height: contentHeight }}>
            <div>{content}</div>
          </DialogContent>
        )}
        <DialogActions>
          <Button color="primary" variant="text" onClick={handleClose}>
            {disapprovalText}
          </Button>
          <Button
            color="primary"
            variant="text"
            disabled={confirmDisabled}
            onClick={handleConfirm}
          >
            {approvalText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

FormDialog.defaultProps = {
  title: "Confirmation Dialog",
  approvalText: "Yes",
  disapprovalText: "No",
  contentHeight: "12vh",
  dialogCallback: () => null,
  content: null,
  confirmDisabled: false,
};

export default FormDialog;
