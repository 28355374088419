import {
  FilterCriteria,
  Incident,
  IncidentSearchResult,
} from "compass-commons";

import { HttpClient } from "compass-shared-services";

const { INCIDENT_MANAGER_PATH } = appConfig;
const httpClient = new HttpClient(appConfig);

const URL_PATHS = {
  INCIDENT_DETAIL: `${INCIDENT_MANAGER_PATH}/incidents/detail`,
  SEARCH_INCIDENTS: `${INCIDENT_MANAGER_PATH}/incidents/list/report`,
  SEARCH_INCIDENTS_BY_PAGINATED: `${INCIDENT_MANAGER_PATH}/incidents/table`,
};

export default class DataExplorerService {
  static async getIncidentById(id: string): Promise<Incident> {
    const urlPath = `${URL_PATHS.INCIDENT_DETAIL}/${id}`;
    return httpClient
      .get<Incident>({ url: urlPath })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async findIncidentsByCriteria(
    criteria: FilterCriteria
  ): Promise<IncidentSearchResult> {
    let incidentSearchResult: IncidentSearchResult;

    return httpClient
      .post<IncidentSearchResult>({
        url: URL_PATHS.SEARCH_INCIDENTS,
        payload: criteria,
      })
      .then((response) => {
        if (response) {
          incidentSearchResult = response;
        }
        return incidentSearchResult;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }
}
