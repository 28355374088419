import React from "react";
import { Incident } from "compass-commons";
import { mapStringDateToTimezone } from "../../../utils/Util";

function IncidentHeaderContent(props: { incident: Incident }): JSX.Element {
  const { incident } = props;

  return (
    <>
      {incident && (
        <div className="timeline-header-content-main">
          <span className="timeline-title-date">
            {mapStringDateToTimezone(incident.creationTimestamp)}
          </span>
          {incident.id && (
            <>
              <br />
              <span className="timeline-incident-code" id="incident-code">
                {incident.id.substring(incident.id.length - 6).toUpperCase()}
              </span>
            </>
          )}
          {incident.incidentDescription && (
            <>
              <br />
              <span className="timeline-incident-description">
                {incident.incidentDescription}
              </span>
            </>
          )}
          {incident.incidentSource && (
            <>
              <br />
              <span className="timeline-default-text">
                {incident.incidentSource}
              </span>
            </>
          )}
          {incident.location && (
            <>
              <br />
              <span className="timeline-location">{incident.location}</span>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default IncidentHeaderContent;
