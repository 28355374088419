import React, { useEffect, useState } from "react";
import { BehaviorSubject } from "rxjs";
import CircularProgressWithLabel from "./CircularProgressWithLabel";

const ReportPdfProgress = ({
  progress,
}: {
  progress: BehaviorSubject<number>;
}): JSX.Element => {
  const [loadingPdfProgress, setLoadingPdfProgress] = useState(
    progress.value || 0
  );

  useEffect(() => {
    const progressPDFSubscription = progress.subscribe((val) => {
      setLoadingPdfProgress(val);
    });

    return function cleanup() {
      progressPDFSubscription.unsubscribe();
    };
  }, [progress]);

  return <CircularProgressWithLabel progress={loadingPdfProgress} />;
};

export default ReportPdfProgress;
