import jsPDF from "jspdf";
import { BehaviorSubject } from "rxjs";
import autoTable from "jspdf-autotable";
import html2canvas from "html2canvas";
import { Incident } from "compass-commons";
import { base64SvgToBase64Png, getFileExtension } from "../utils/Util";
import GetMediaFileError from "../errors/GetMediaFileError";
import {
  getMediaFiles,
  centeredText,
  getIndexOfGenericInfo,
  addGenericInfo,
  rightMarginText,
} from "../utils/PDFUtils";
import { ReportGenerateSource } from "../models/ReportGenerateSource";

interface MediaHolder {
  executionId: string;
  taskId: string;
  medias: { mediaContent: string; caption: string }[];
}

interface IncidentIconHolder {
  pageNumber: number;
  element: HTMLElement;
}

const { PDF_PAGES_LIMIT } = appConfig;
const EMAIL: ReportGenerateSource = "email";

export default class ReportPDFService {
  private translate;

  readonly COMMON_PAGE_SIZE_PX = 680;

  readonly TABLE_PAGE_SIZE_PX = 1510;

  constructor(translate: any) {
    this.translate = translate;
  }

  startGeneratingPdf = (
    source: NonNullable<ReportGenerateSource>,
    generatingPdfSubject?: BehaviorSubject<ReportGenerateSource>
  ) => {
    if (generatingPdfSubject) {
      generatingPdfSubject.next(source);
    }
  };

  stopGeneratingPdf = (
    generatingPdfSubject?: BehaviorSubject<ReportGenerateSource>
  ) => {
    if (generatingPdfSubject) {
      generatingPdfSubject.next(null);
    }
  };

  updateGeneratingPdfProgress = (
    progressPdfSubject: BehaviorSubject<number>,
    progress: number
  ) => {
    if (progressPdfSubject) {
      progressPdfSubject.next(progress);
    }
  };

  /**
   * Estimates the PDF size (number of pages) based on the size (in pixels) of the incidents in the preview table.
   * @returns boolean
   */
  isPdfSizeValid = (): boolean => {
    const incidents = document.getElementsByClassName(
      "main-panel-list-item-div"
    );
    let pagesCount = 1; // Cover page
    for (let i = 0; i < incidents.length; i++) {
      const isTable = !!incidents[i].querySelector("#incidents-table");
      pagesCount += isTable
        ? Math.round(incidents[i].clientHeight / this.TABLE_PAGE_SIZE_PX)
        : Math.ceil(incidents[i].clientHeight / this.COMMON_PAGE_SIZE_PX);
    }
    return pagesCount <= parseInt(PDF_PAGES_LIMIT, 10);
  };

  /**
   * Creates a PDF assuming a structure of: GrandParent -> Parent -> Children (items to add to pdf)
   * */
  createPdf = async (
    html: HTMLElement,
    saveDoc: boolean,
    source: NonNullable<ReportGenerateSource>,
    incidentDetails: Incident[] = null,
    generatingPdfSubject?: BehaviorSubject<ReportGenerateSource>,
    progressPdf?: BehaviorSubject<number>,
    reportName = "report"
  ) => {
    this.startGeneratingPdf(source, generatingPdfSubject);
    // eslint-disable-next-line new-cap
    const doc = new jsPDF("p", "pt", "a4", true);
    this.updateGeneratingPdfProgress(progressPdf, 0);

    const incrementProgress = (progress: number) =>
      this.updateGeneratingPdfProgress(
        progressPdf,
        progressPdf.value + progress
      );

    this.addFrontPage(doc, reportName); // first page
    doc.setTextColor("black");
    doc.setFontSize(11);
    let mediaFiles: MediaHolder[] = [];
    try {
      try {
        // Increase progress in 60% when finish
        await getMediaFiles(html, incidentDetails, incrementProgress).then(
          (res) => {
            mediaFiles = res;
          }
        );
      } catch (error) {
        throw new GetMediaFileError(error);
      }
      // Increase progress in 35% when finish
      await this.addTableFromHTML(
        doc,
        html,
        incidentDetails,
        mediaFiles,
        incrementProgress
      );

      this.addFooters(doc);
      if (saveDoc) {
        doc.save(`${reportName}.pdf`);
      }
    } finally {
      // Email progress should only finish after sending the email
      if (source !== EMAIL) this.stopGeneratingPdf(generatingPdfSubject);
      mediaFiles.forEach((m) => {
        m?.medias.forEach((link) => {
          URL.revokeObjectURL(link.mediaContent);
        });
      });
    }
    return doc.output("blob");
  };

  // Private methods
  private addFrontPage = (doc: jsPDF, reportName) => {
    doc.setTextColor("black");
    doc.setFontSize(14);
    centeredText(doc, this.translate("pdf.brandName"), 50);
    centeredText(doc, this.translate("pdf.preparedBy"), 300);
    const img = new Image();
    img.src = `${appConfig.ASSET_STORE}${
      appConfig.DMS_LOGO_PATH || "custom-template/adms_oficial_logo.png"
    }`;
    doc.addImage(
      img,
      "PNG",
      (doc.internal.pageSize.width - 300) / 2,
      360,
      300,
      79
    );
    const date = new Date();
    const month = date.toLocaleString("default", { month: "long" });
    centeredText(doc, `${month} ${date.getFullYear()}`, 560);
    doc.setFontSize(35);
    centeredText(doc, reportName, 200);
  };

  private addTableFromHTML = async (
    doc: jsPDF,
    html: HTMLElement,
    incidentDetails: Incident[],
    mediaFiles: MediaHolder[],
    incrementProgress: (progress: number) => void
  ) => {
    const tables: HTMLCollectionOf<HTMLTableElement> =
      html.getElementsByTagName("table");
    const incidentIconsOfDetailTables: IncidentIconHolder[] = [];
    for (let i = 0; i < tables?.length; i++) {
      const table = tables.item(i);
      doc.addPage();
      let startY = 90;
      if (table.id === "details-table") {
        startY = 100;
        centeredText(doc, this.getIncidentDetailText(table), 70);
        this.addDetailsBody(
          table,
          mediaFiles,
          doc,
          startY,
          incidentDetails,
          incidentIconsOfDetailTables
        );
      } else if (table.id === "incidents-table") {
        centeredText(doc, this.translate("pdf.multipleIncidents"), 70);
        autoTable(doc, {
          includeHiddenHtml: true,
          html: table,
          margin: 35,
          showHead: "firstPage",
          startY,
          theme: "striped",
          headStyles: { fillColor: "#0079a8" },
        });
      }
      incrementProgress(15 / tables.length);
    }

    for (let p = 0; p < incidentIconsOfDetailTables.length; p++) {
      const incidentIcon = incidentIconsOfDetailTables[p];
      doc.setPage(incidentIcon.pageNumber);
      await this.addIconSvgToTableHeader(doc, incidentIcon.element);
      incrementProgress(20 / tables.length);
    }
  };

  private addDetailsBody(
    table: HTMLTableElement,
    mediaFiles: MediaHolder[],
    doc: jsPDF,
    startY: number,
    incidentDetails: Incident[],
    incidentIcons: IncidentIconHolder[]
  ) {
    this.addMediaFilesAsRows(table, mediaFiles);

    autoTable(doc, {
      html: table,
      margin: 35,
      startY,
      useCss: true,
      tableWidth: "auto",
      theme: "striped",
      styles: {
        fillColor: [236, 236, 236],
      },
      columnStyles: {
        0: {
          cellWidth: 80,
        },
        1: {
          cellWidth: 40,
        },
        2: {
          cellWidth: 400,
        },
      },
      didParseCell(data) {
        const genericInfoIndex = getIndexOfGenericInfo(
          data,
          "operation-geninfo-card__wrapper"
        );
        if (
          genericInfoIndex > -1 &&
          data.column.dataKey === 2 &&
          data.cell.section === "body"
        ) {
          data.cell.styles.cellPadding = { horizontal: 12, vertical: 100 };
        }

        if (data.column.index === 2 && data.cell.section === "body") {
          const td = data.cell.raw;
          const img = (td as HTMLTableCellElement).getElementsByTagName(
            "img"
          )[0];
          if (img && img.className === "printable-images") {
            data.cell.height = img.height;
            data.cell.contentHeight = img.height || 300;
          }
        }
      },
      willDrawCell(data) {
        if ((data as any).cursor.y + 120 > 800) {
          doc.addPage();
          (data as any).cursor.y = 70;
          data.cell.y = 70;
        }
      },
      didDrawCell(data) {
        const genericInfoIndex = getIndexOfGenericInfo(
          data,
          "operation-geninfo-card__wrapper"
        );

        if (data.column.index === 0 && data.cell.section === "body") {
          const td = data.cell.raw;
          const svg = (td as HTMLTableCellElement).getElementsByTagName(
            "svg"
          )[0];

          if (svg?.parentElement.classList.contains("incident-icon")) {
            incidentIcons.push({
              pageNumber: doc.getCurrentPageInfo().pageNumber,
              element: svg.parentElement,
            });
          }
        }

        if (
          genericInfoIndex > -1 &&
          data.column.dataKey === 2 &&
          data.cell.section === "body"
        ) {
          addGenericInfo(doc, data, genericInfoIndex, incidentDetails);
        }

        if (data.column.index === 2 && data.cell.section === "body") {
          const td = data.cell.raw;
          const img = (td as HTMLTableCellElement).getElementsByTagName(
            "img"
          )[0];

          if (img && img.className === "printable-images") {
            data.row.height = img.height;
            const textPos = data.cell.getTextPos();
            doc.addImage(
              img.src,
              textPos.x,
              textPos.y + 10,
              img.width,
              img.height - 10
            );
          }
        }
      },
    });

    return incidentIcons;
  }

  private addMediaFilesAsRows(
    table: HTMLTableElement,
    mediaFiles: MediaHolder[]
  ) {
    // add media files after generic info
    const className = "operation-geninfo-card__wrapper";
    const elementNumber = table.getElementsByClassName(className).length;

    // Loop to find the media files element
    for (let i = 0; i < elementNumber; i++) {
      const { index, taskId } = this.findRowIndexByClass(table, className, i);

      if (taskId && index) {
        const incidentStepMedias = mediaFiles.find(
          (o: MediaHolder) => o.taskId === taskId
        );

        if (incidentStepMedias) {
          ReportPDFService.addGenericInfoMediaRows(
            incidentStepMedias,
            table,
            index
          );

          // Remove original captions
          const snapshotsElement = table.getElementsByClassName(className)[i];
          snapshotsElement.remove();
        }
      }
    }
  }

  private static addGenericInfoMediaRows(
    incidentStepMedias: MediaHolder,
    table: HTMLTableElement,
    index: number
  ) {
    for (let j = 0; j < incidentStepMedias.medias.length; j++) {
      const content: any = incidentStepMedias?.medias[j];

      // Add image as row
      const newRow = table.insertRow(index + j * 2 + 1);
      newRow.insertCell(0);
      newRow.insertCell(1);
      newRow.className = "media-row";
      const detailCell = newRow.insertCell(2);
      newRow.insertCell(3);
      const image = new Image();
      image.src = content.mediaContent;
      image.width = 380 * (2 / 3);
      image.height = 320 * (2 / 3) + 10;
      image.crossOrigin = "anonymous";
      image.className = "printable-images";
      detailCell.innerHTML = image.outerHTML;

      // Add caption as row
      const captionRow = table.insertRow(index + j * 2 + 2);
      captionRow.insertCell(0);
      captionRow.insertCell(1);
      const captionDetailCell = captionRow.insertCell(2);
      captionRow.insertCell(3);
      const figcaption = document.createElement("figcaption");
      figcaption.innerText = content.caption;
      figcaption.className = "dms-type-hint";
      captionDetailCell.innerHTML = figcaption.outerHTML;
    }
  }

  private getIncidentDetailText = (table: HTMLTableElement) => {
    const incidentHeader = table.getElementsByTagName("span");
    const incidentCode =
      incidentHeader?.length >= 2
        ? `: ${incidentHeader.item(1).innerText}`
        : "";
    return `${this.translate("pdf.detailsOf")}${incidentCode}`;
  };

  private findRowIndexByClass = (
    table: HTMLTableElement,
    className: string,
    elementIndex = 0
  ): { index: number; executionId: string; taskId: string } => {
    const element = table.getElementsByClassName(className)[elementIndex];
    if (element) {
      const { rowIndex } = element.closest("tr");
      const elementId = element.getAttribute("id");
      let taskId;
      let executionId;
      if (elementId) {
        // eslint-disable-next-line prefer-destructuring
        executionId = elementId.split("#")[0];
        // eslint-disable-next-line prefer-destructuring
        taskId = elementId.split("#")[1];
      }
      return { index: rowIndex || -1, executionId, taskId };
    }

    return { index: undefined, executionId: undefined, taskId: undefined };
  };

  private addImageToDoc = async (
    img: HTMLImageElement,
    doc: jsPDF,
    properties: {
      x;
      y;
      w;
      h;
    },
    imageFormat = "PNG"
  ) => {
    if (img) {
      if (getFileExtension(img.src) === "svg") {
        const pngBase64 = await base64SvgToBase64Png(img.src, properties.w);
        doc.addImage(
          pngBase64,
          imageFormat,
          properties.x,
          properties.y,
          properties.w,
          properties.h
        );
      } else {
        doc.addImage(
          img.src,
          imageFormat,
          properties.x,
          properties.y,
          properties.w,
          properties.h
        );
      }
    }
  };

  private addHTMLElementToDoc = async (
    element: HTMLElement,
    doc: jsPDF,
    properties: {
      x;
      y;
      w;
      h;
    },
    useImageFromElement = false,
    imageFormat = "PNG"
  ) => {
    const canvas = await html2canvas(element, {
      backgroundColor: null,
      useCORS: useImageFromElement, // this is optional because in some cases it can reduce quality
    });
    doc.addImage(
      canvas.toDataURL("image/png"),
      imageFormat,
      properties.x,
      properties.y,
      properties.w,
      properties.h
    );
  };

  /**
   * This function adds an Icon to the PDF by first loading the HTML element
   * which might contain background and css properties
   * and then by adding the image itself on top as a PNG.
   * @param table
   * @param doc
   * @param iconClassName
   * @param useImageFromElement
   */
  private addIconSvgToTableHeader = async (
    doc: jsPDF,
    iconDiv: HTMLElement,
    useImageFromElement = false
  ) => {
    const imageGap = 15;
    const iconProperties = {
      x: 45,
      y: 105,
      w: 40,
      h: 40,
    };
    if (iconDiv) {
      await this.addHTMLElementToDoc(
        iconDiv,
        doc,
        iconProperties,
        useImageFromElement
      );
    }

    const img: HTMLCollectionOf<HTMLImageElement> =
      iconDiv.getElementsByTagName("img");
    if (img?.length > 0 && !useImageFromElement) {
      const imgProperties = {
        w: iconProperties.w - imageGap, // reduce the image size to be smaller than the background icon
        h: iconProperties.h - imageGap,
      };
      await this.addImageToDoc(img.item(0), doc, {
        ...imgProperties,
        x: iconProperties.x + (iconProperties.w - imgProperties.w) / 2, // Center the image in x-axis
        y: iconProperties.y + (iconProperties.h - imgProperties.h) / 2, // Center the image in y-axis
      });
    }
  };

  private addFooters = (doc: jsPDF) => {
    const pageCount = doc.getNumberOfPages();

    doc.setFont("helvetica", "italic");
    doc.setFontSize(8);
    for (let i = 2; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(
        this.translate("pdf.productName"),
        30,
        doc.internal.pageSize.height - 20
      );
      rightMarginText(
        doc,
        this.translate("pdf.brandName"),
        doc.internal.pageSize.height - 20
      );
      doc.text(
        this.translate("pdf.pageFooter", {
          pageNumber: i,
          totalPages: pageCount,
        }),
        doc.internal.pageSize.width / 2,
        doc.internal.pageSize.height - 20,
        {
          align: "center",
        }
      );
    }
  };
}
