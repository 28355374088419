// General
import React from "react";
import {
  IncidentStep,
  ResourceInteractionSource,
  safelyFormatDate,
  useI18n,
  User,
} from "compass-commons";
// Components
import { SupportAgentRounded } from "@mui/icons-material";
// Models
import ResourceInteractionStep from "../../../models/resource/ResourceInteractionStep";

const getTranslated = (configText) => {
  const { t: translate } = useI18n();

  return translate(configText);
};

const getWithOperatorIcon = (content, operator: User): JSX.Element => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flex: "0.15",
        }}
      >
        <div
          className="small-circle compass-box-shadow"
          style={{
            color: "rgb(38,122,188)",
            backgroundColor: "var(--operatorIconBlueColor)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SupportAgentRounded />
        </div>
        <span
          style={{
            color: "white",
            fontSize: "small",
            position: "absolute",
          }}
          title={`${operator.firstName} ${operator.lastName}`}
        >
          {operator.nameInitials}
        </span>
      </div>
      <div style={{ paddingLeft: "5%", flex: "1" }}>{content}</div>
    </div>
  );
};

const getResourceInteractionSourceText = (
  interactionSource: ResourceInteractionSource
): string => {
  switch (interactionSource) {
    case ResourceInteractionSource.SITE_LAYOUT: {
      return getTranslated("resources.interactionTypeSiteLayout");
    }
    case ResourceInteractionSource.RELATED_RESOURCE: {
      return getTranslated("resources.interactionTypeRelatedResource");
    }
    case ResourceInteractionSource.OPERATOR_GUIDE_TASK: {
      return getTranslated("resources.interactionTypeOperatorGuideTask");
    }
    default: {
      return getTranslated("resources.interactionTypeOperatorGuideTask");
    }
  }
};

const getSpan = (
  value?: string,
  className?: string,
  withBreakLine = false
): JSX.Element => {
  if (value && value.length > 0) {
    return (
      <>
        {withBreakLine && <br />}
        <span className={className}>{value}</span>
      </>
    );
  }
  return <></>;
};

const getDefaultTextSpan = (value?: string): JSX.Element => {
  return getSpan(value, "timeline-default-text");
};

const getPostAlarmContent = (incidentStep: IncidentStep): JSX.Element => {
  return (
    <div data-cr={incidentStep.type}>
      {incidentStep.title
        ? getDefaultTextSpan(incidentStep.title)
        : getDefaultTextSpan(incidentStep.type)}
      {incidentStep.device && incidentStep.creationTimestamp && (
        <>
          <br />
          <span className="timeline-small-text">
            {incidentStep.device} -{" "}
            {safelyFormatDate(incidentStep.creationTimestamp)}
          </span>
        </>
      )}
      {incidentStep.clearTimestamp && (
        <>
          <br />
          <span className="timeline-small-text">
            Available at {safelyFormatDate(incidentStep.clearTimestamp)}
          </span>
        </>
      )}
    </div>
  );
};

const getGenericContent = (incidentStep: IncidentStep): JSX.Element => {
  return (
    <div data-cr={incidentStep.type}>
      {incidentStep.title
        ? getDefaultTextSpan(incidentStep.title)
        : getDefaultTextSpan(incidentStep.type)}
      {getSpan(incidentStep.description, "timeline-small-text", true)}
      {getSpan(incidentStep.device, "timeline-small-text", true)}
      {getSpan(incidentStep.instruction, "timeline-small-text", true)}
      {incidentStep.userText && (
        <>
          {" "}
          <span className="timeline-user-text">{incidentStep.userText}</span>
        </>
      )}
      {getSpan(
        safelyFormatDate(incidentStep.creationTimestamp),
        "timeline-small-text",
        true
      )}
    </div>
  );
};

const getAssignedContent = (incidentStep: IncidentStep): JSX.Element => {
  return (
    <div className="timeline-header-content-main" data-cr={incidentStep.type}>
      {getDefaultTextSpan(incidentStep.title)}
      {getSpan(incidentStep.device, "timeline-small-text", true)}
      {incidentStep.creationTimestamp && (
        <>
          <span className="timeline-small-text">
            <b>{safelyFormatDate(incidentStep.creationTimestamp)}</b>
          </span>
        </>
      )}
    </div>
  );
};

const getManualContent = (incidentStep: IncidentStep): JSX.Element => {
  return (
    <div data-cr={incidentStep.type}>
      {getDefaultTextSpan(incidentStep.title)}
      {getSpan(incidentStep.device, "timeline-small-text", true)}
      {getDefaultTextSpan(incidentStep.instruction)}
      {incidentStep.userText && incidentStep.userText.length > 1 && (
        <>
          <br />
          <span className="timeline-user-text">{incidentStep.userText}</span>
        </>
      )}
    </div>
  );
};

const getCommentContent = (incidentStep: IncidentStep): JSX.Element => {
  return (
    <div data-cr={incidentStep.type}>
      {incidentStep.title
        ? getDefaultTextSpan(incidentStep.title)
        : getDefaultTextSpan(incidentStep.type)}
      {incidentStep.userText && (
        <>
          <br />
          <div className="timeline-user-comment">
            <span className="timeline-user-comment-inner">
              {incidentStep.userText}
            </span>
          </div>
        </>
      )}
    </div>
  );
};

const getClearContent = (incidentStep: IncidentStep): JSX.Element => {
  return (
    <b data-cr={incidentStep.type}>
      Clear | {safelyFormatDate(incidentStep.creationTimestamp)}
    </b>
  );
};

const getResourceInteractionContent = (
  incidentStep: ResourceInteractionStep
): JSX.Element => {
  return incidentStep.resourceInteraction ? (
    <>
      <div data-cr={incidentStep.type}>
        <>
          {incidentStep.resourceInteraction.resourceName && (
            <>
              <div className="timeline-user-resource-interaction">
                <span
                  className="timeline-user-resource-interaction-span"
                  data-cr="resource-interaction-span"
                >
                  {incidentStep.resourceInteraction.resourceName}
                </span>
                <br className="timeline-user-resource-interaction-source-break" />
                <span
                  className="timeline-user-resource-interaction-source-span"
                  data-cr="resource-interaction-source-span"
                >
                  {getResourceInteractionSourceText(
                    incidentStep.resourceInteraction.interactionSource
                  )}
                </span>
              </div>
            </>
          )}
        </>
      </div>
    </>
  ) : (
    <>Empty Content</>
  );
};

const getActivationsGroupContent = (
  incidentStep: IncidentStep
): JSX.Element => {
  const { t: translate } = useI18n();
  return (
    <b data-cr={incidentStep.type}>
      {`${translate("resources.activationsCount", {
        activationsCount: incidentStep.activationsCount,
      })}`}
    </b>
  );
};

export {
  getWithOperatorIcon,
  getDefaultTextSpan,
  getPostAlarmContent,
  getGenericContent,
  getAssignedContent,
  getManualContent,
  getCommentContent,
  getClearContent,
  getResourceInteractionContent,
  getActivationsGroupContent,
};
