// Generic
import React from "react";
// Styles
import "./infoFieldText.module.css";
import { InfoFieldText } from "compass-commons";

// Models

interface InfoFieldTextProps {
  infoFieldText: InfoFieldText;
}

const InfoFieldTextComponent = ({
  infoFieldText,
}: InfoFieldTextProps): JSX.Element => {
  const { label, text } = infoFieldText || {};
  return (
    <div
      key={`operation-info-field-details-${label}`}
      className="operation-info-field__details"
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "var(--msi-ui-spacing-xs)",
        marginBottom: "var(--msi-ui-spacing-xs)",
      }}
    >
      <label
        style={{
          fontWeight: "600",
          fontSize: "0.8rem",
          wordBreak: "break-word",
        }}
        htmlFor={label}
      >
        {label}:
      </label>
      <p
        style={{
          width: "100%",
          padding: "var(--msi-ui-spacing-xs)",
          marginBottom: "0",
          borderRadius: "2px",
          fontSize: "0.875rem",
          backgroundColor: "rgba(0,0,0,0.1)",
        }}
        id={label}
      >
        {text}
      </p>
    </div>
  );
};

export default InfoFieldTextComponent;
