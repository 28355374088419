import React from "react";
import { DownloadRounded } from "@mui/icons-material";
import { BehaviorSubject } from "rxjs";
import SideActions from "./SideActions";
import ReportPdfProgress from "../../commons/loadingProgress/ReportPdfProgress";

interface DownloadProps {
  onClick: any;
  loadingProgress: BehaviorSubject<number>;
  loading: boolean;
  disabled?: boolean;
}

const Download = ({
  onClick,
  disabled,
  loading,
  loadingProgress,
}: DownloadProps): JSX.Element => {
  return (
    <SideActions
      accessor={<span>Download</span>}
      accessorPosition="right"
      disabled={disabled || loading}
      icon={
        loading ? (
          <ReportPdfProgress progress={loadingProgress} />
        ) : (
          <DownloadRounded color="primary" />
        )
      }
      onClick={onClick}
    />
  );
};

export default Download;
