// Generic
import React from "react";
// Styles
import "./infoFieldUrl.module.css";
import { InfoFieldUrl } from "compass-commons";

// Models

interface InfoFieldUrlProps {
  infoFieldUrl: InfoFieldUrl;
}

const InfoFieldUrlComponent = ({
  infoFieldUrl,
}: InfoFieldUrlProps): JSX.Element => {
  const { label, url } = infoFieldUrl || {};
  return (
    <div
      key={`operation-info-field-details-${label}`}
      className="operation-info-field__details"
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "var(--msi-ui-spacing-xs)",
        marginBottom: "var(--msi-ui-spacing-xs)",
      }}
    >
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        key={`operation-info-field-url-${label}`}
        className="operation-info-field__url"
      >
        {label}
      </a>
    </div>
  );
};

export default InfoFieldUrlComponent;
