import React from "react";
import { ArrowBackRounded } from "@mui/icons-material";
import { isUnity } from "compass-commons";

import "./buttons.module.css";
import SideActions from "./SideActions";
import StateService from "../../../services/StateService";
import { DATA_EXPLORER_PATH } from "../../../routes/route";

const Back = (): JSX.Element => {
  const stateService: StateService = new StateService();
  const { currentReportCart } = stateService;
  const onClick = () => {
    currentReportCart.next(null);

    window.location.href = isUnity
      ? `${window.location.pathname}#${DATA_EXPLORER_PATH}`
      : appConfig.COMPASS_DATA_EXPLORER_PATH;
  };
  return (
    <SideActions
      accessor={<span>Back</span>}
      accessorPosition="left"
      onClick={onClick}
      icon={<ArrowBackRounded color="primary" />}
    />
  );
};

export default Back;
