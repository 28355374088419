// Generic
import React, { useEffect, useRef, useState } from "react";
// Styles
import "./infoFieldPhoto.module.css";
// Components
import { Image } from "dms-lib";
// Models
import {
  InfoFieldPhoto,
  MediaInfo,
  isMediaAvailable,
  isMediaWaiting,
  MediaFileStatus,
} from "compass-commons";
import { InfoFieldPhotoShort } from "../../../../../models/media/InfoFieldPhotoShort";
import GetMediaFileError from "../../../../../errors/GetMediaFileError";
// Services
import MediaFileManagerService from "../../../../../services/MediaFileManagerService";
// Helpers
import { buildCaption } from "../../../../../helpers/captionHelper";

interface InfoFieldPhotoProps {
  infoFieldPhoto: InfoFieldPhoto;
  onImgClick?: (infoFieldPhotoShort: InfoFieldPhotoShort) => void;
}

const InfoFieldPhotoComponent = ({
  infoFieldPhoto,
  onImgClick,
}: InfoFieldPhotoProps): JSX.Element => {
  const [imageData, setImageData] = useState<InfoFieldPhoto>(infoFieldPhoto);

  // Create a media file Polling for each image
  // Purpose: Check if image is still being proccessed
  // Only trigger setTimeout if image is still being prepared
  const imagePollingTimeout = useRef(null);

  const mediaPollingCondition = (mediaFile: MediaInfo) =>
    !mediaFile || isMediaWaiting(mediaFile.mediaFileStatus);

  const startPolling = (fileId: string, mediaFile: MediaInfo) =>
    fileId && mediaPollingCondition(mediaFile);

  const getMedia = async (): Promise<MediaInfo> => {
    try {
      const result = await MediaFileManagerService.getMediaById(
        imageData.fileId
      );
      return result;
    } catch (error) {
      clearTimeout(imagePollingTimeout?.current);
      throw new GetMediaFileError(error);
    }
  };

  const mediaPolling = async () => {
    let mediaInfo: MediaInfo;
    let pollingError = false;
    try {
      mediaInfo = await getMedia();

      if (mediaPollingCondition(mediaInfo)) {
        imagePollingTimeout.current = setTimeout(mediaPolling, 1000);
        return;
      }
    } catch (error) {
      pollingError = true; // Importanto flag to allert finally that an error occurred
    } finally {
      // Important to keep track of what was the previous state.
      // When polling is possible that mediaFile is still null therefore we need to keep polling.
      setImageData((prevState: InfoFieldPhoto) => ({
        ...prevState,
        mediaFile: {
          ...(mediaInfo || prevState?.mediaFile),
          thumbnail: {
            ...(mediaInfo?.thumbnail || prevState?.mediaFile?.thumbnail),
          },
          mediaFileStatus:
            (pollingError && MediaFileStatus.UPLOAD_FAILED) ||
            mediaInfo?.mediaFileStatus ||
            prevState?.mediaFile?.mediaFileStatus,
        },
      }));
    }
  };

  // Conditions to handle different use cases
  const showMedia =
    imageData.fileId && isMediaAvailable(imageData.mediaFile?.mediaFileStatus);

  const isPolling = startPolling(imageData.fileId, imageData.mediaFile);

  useEffect(() => {
    if (startPolling(imageData.fileId, imageData.mediaFile)) mediaPolling();

    return () => {
      clearTimeout(imagePollingTimeout?.current);
    };
  }, []);

  return (
    <Image
      id={imageData.fileId}
      src={`data:${imageData.mediaFile?.thumbnail?.mediaType};base64,${imageData.mediaFile?.thumbnail?.content}`}
      alt="addition info"
      imageClassName="operation-info-field__photo"
      hasError={!showMedia}
      isLoading={isPolling}
      caption={buildCaption(
        {
          fileId: infoFieldPhoto.fileId,
          resourceMappingName: imageData.resourceMapping?.name,
          mediaTimestamp: imageData.mediaFile?.mediaTimestamp,
        },
        " | "
      )}
      onClick={() =>
        onImgClick({
          fileId: imageData.fileId,
          resourceMappingName: imageData.resourceMapping?.name,
          mediaTimestamp: imageData.mediaFile?.mediaTimestamp,
        })
      }
    />
  );
};

InfoFieldPhotoComponent.defaultProps = {
  onImgClick: undefined,
};

export default InfoFieldPhotoComponent;
