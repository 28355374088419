import "./buttons.module.css";
import { Fab } from "@mui/material";
import React, { ReactElement } from "react";

interface ActionButtonProps {
  accessor?: ReactElement;
  accessorPosition?: "left" | "right";
  className?: string;
  disabled?: boolean;
  onClick: () => void;
  icon: ReactElement;
}

const SideActions = ({
  accessor,
  accessorPosition,
  className,
  disabled,
  onClick,
  icon,
}: ActionButtonProps): JSX.Element => {
  return (
    <div className={`action-button ${className}`}>
      {accessorPosition === "left" && accessor}
      <Fab size="small" onClick={onClick} disabled={disabled}>
        {icon}
      </Fab>
      {accessorPosition === "right" && accessor}
    </div>
  );
};

SideActions.defaultProps = {
  accessor: null,
  accessorPosition: null,
  className: "",
  disabled: false,
};

export default SideActions;
