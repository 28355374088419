import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "dms-lib";
import { useEffect, useState } from "react";

interface AlertDialogProps {
  title?: string;
  content?: string;
  approvalText?: string;
  disapprovalText?: string;
  isDialogOpen?: boolean;
  dialogCallback?: (open: boolean) => void;
}

const AlertDialog = (props: AlertDialogProps): JSX.Element => {
  const {
    content,
    title,
    approvalText,
    disapprovalText,
    isDialogOpen,
    dialogCallback,
  } = props;
  const [isOpen, setIsOpen] = useState(isDialogOpen);

  useEffect(() => {
    setIsOpen(isDialogOpen);
  }, [isDialogOpen]);

  const handleClose = () => {
    setIsOpen(false);
    dialogCallback(false);
  };

  const handleCloseConfirm = () => {
    setIsOpen(false);
    dialogCallback(true);
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent style={{ height: "12vh" }}>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: "rgba(0, 0, 0, 1)" }}
          >
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="text" onClick={handleClose}>
            {disapprovalText}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleCloseConfirm}
          >
            {approvalText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

AlertDialog.defaultProps = {
  title: "Confirmation Dialog",
  content: "Do you confirm?",
  approvalText: "Yes",
  disapprovalText: "No",
  isDialogOpen: false,
};

export default AlertDialog;
