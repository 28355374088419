// General
import React, { useEffect, useRef, useState } from "react";
import {
  useFeatureFlag,
  Incident,
  LocalizationNS,
  ReportCart,
  ReportCartStateEnum,
  useI18n,
} from "compass-commons";
import { BehaviorSubject } from "rxjs";
// Components
import { Button, Alert } from "dms-lib";
import SideButtons from "./components/commons/sideButtons/SideButtons";
import Back from "./components/mainPanel/buttons/Back";
import Delete from "./components/mainPanel/buttons/Delete";
import MainPanel from "./components/mainPanel/MainPanel";
import Send from "./components/mainPanel/buttons/Send";
import Download from "./components/mainPanel/buttons/Download";
// Services
import ReportCartService from "./services/ReportCartService";
import ReportPDFService from "./services/ReportPDFService";
// Utils
import { createReportCart, normalizeCart } from "./utils/Util";
import { SHOW_GENERIC_ERROR_MESSAGES_FEATURE_FLAG } from "./utils/Constants";
// Errors
import MoreThanOneActiveReportCart from "./errors/MoreThanOneActiveReportCart";
import ActiveReportCartNotExistsError from "./errors/ActiveReportCartNotExistsError";
// Models
import { ReportEmail } from "./models/ReportEmail";
// Helpers
import useWindowDimensions from "./helpers/useWindowDimensions";
import { ReportGenerateSource } from "./models/ReportGenerateSource";
import StateService from "./services/StateService";

interface AppLayoutProps {
  currentReportCart: BehaviorSubject<ReportCart>;
  generatingPdf: BehaviorSubject<ReportGenerateSource>;
  progressPdf: BehaviorSubject<number>;
  incidentDetails: BehaviorSubject<Incident[]>;
}

const stateService: StateService = new StateService();

const AppLayout = ({
  currentReportCart,
  generatingPdf,
  incidentDetails,
  progressPdf,
}: AppLayoutProps): JSX.Element => {
  const { t: translate } = useI18n();
  const [reportCart, setReportCart] = useState(null);
  const { alertSubject } = stateService;
  const [loadingPdf, setLoadingPdf] = useState(generatingPdf.value || false);
  const downloadButtonRef = useRef();
  const [selectedDetails, setSelectedDetails] = useState(incidentDetails.value);
  const reportPdfService = new ReportPDFService(translate);
  const dimensions = useWindowDimensions();
  const appStyle = {
    height: dimensions.height - 90,
  };

  useEffect(() => {
    if (reportCart == null) {
      ReportCartService.getCarts(ReportCartStateEnum.ACTIVE)
        .then((res) => {
          if (res && res.length > 0) {
            if (res.length === 1) {
              const normalizedCart = normalizeCart(res[0]);
              currentReportCart.next(normalizedCart);
              setReportCart(normalizedCart);
            } else {
              throw new MoreThanOneActiveReportCart();
            }
          } else {
            createReportCart()
              .then((cart) => {
                if (cart) {
                  setReportCart(cart);
                  currentReportCart.next(cart);
                }
              })
              .catch(() => {
                setReportCart(null);
                currentReportCart.next(null);
              });
          }
        })
        .catch((error) => {
          throw new ActiveReportCartNotExistsError(error);
        });
    }

    const generatingPDFSubscription = generatingPdf.subscribe((val) => {
      setLoadingPdf(val);
    });

    const reportCartSubscription = currentReportCart.subscribe((val) => {
      setReportCart(val);
    });

    const incidentDetailsSubscription = incidentDetails.subscribe((val) => {
      if (val) setSelectedDetails(val);
    });

    return function cleanup() {
      generatingPDFSubscription.unsubscribe();
      reportCartSubscription.unsubscribe();
      incidentDetailsSubscription.unsubscribe();
    };
  }, [currentReportCart, reportCart, incidentDetails, generatingPdf]);

  const isPdfSizeValid = () => {
    if (!reportPdfService.isPdfSizeValid()) {
      alertSubject.next({
        title: translate("pdf.pagesLimitReached"),
      });
      return false;
    }
    return true;
  };

  const createPdf = () => {
    if (!generatingPdf?.value) {
      if (!isPdfSizeValid()) return;

      reportPdfService
        .createPdf(
          downloadButtonRef.current,
          true,
          "download",
          selectedDetails,
          generatingPdf,
          progressPdf,
          reportCart?.name
        )
        .then((blob) => {
          if (blob) {
            ReportCartService.uploadReportPDF(
              blob,
              currentReportCart.getValue()
            )
              .then(() => {
                alertSubject.next({
                  title: translate("pdf.createReport"),
                  severity: "success",
                });
              })
              .catch(() => {
                alertSubject.next({
                  title: translate("pdf.failedCreateReport"),
                });
              });
          }
        });
    }
  };

  const sendReportViaEmail = (emailDto: ReportEmail) => {
    reportPdfService
      .createPdf(
        downloadButtonRef.current,
        false,
        "email",
        selectedDetails,
        generatingPdf,
        progressPdf,
        reportCart?.name
      )
      .then((blob) => {
        if (blob) {
          emailDto.reportName = reportCart?.name ? reportCart.name : "Report";
          emailDto.reportCartId = reportCart.id;
          ReportCartService.sendEmail(blob, emailDto, reportCart)
            .then(() => {
              alertSubject.next({
                title: translate("email.sendReport"),
                severity: "success",
              });
            })
            .catch(() => {
              alertSubject.next({
                title: translate("email.failedSendReport"),
              });
            })
            .finally(() => {
              generatingPdf.next(null);
            });
        } else {
          alertSubject.next({
            title: translate("email.failedSendReport"),
          });
          generatingPdf.next(null);
        }
      });
  };
  const { enabled: showErrorMessagesFeatureFlag } = useFeatureFlag(
    appConfig,
    SHOW_GENERIC_ERROR_MESSAGES_FEATURE_FLAG
  );
  const handleRefresh = () => {
    window.location.reload();
  };
  const refreshAction = (
    <Button size="small" color="primary" variant="text" onClick={handleRefresh}>
      Refresh
    </Button>
  );
  useEffect(() => {
    const handleReject = () => {
      if (!showErrorMessagesFeatureFlag) return;
      alertSubject.next({
        title: translate("genericErrorTitle", { ns: LocalizationNS.SHARED }),
        description: translate("genericErrorSubtitle", {
          ns: LocalizationNS.SHARED,
        }),
        action: refreshAction,
      });
    };
    window.addEventListener("unhandledrejection", handleReject);
    return () => {
      window.removeEventListener("unhandledrejection", handleReject);
    };
  }, [showErrorMessagesFeatureFlag]);

  return (
    <div
      className="report-checkout-main-div"
      style={appStyle}
      data-cr="report-checkout-main"
    >
      <div className="report-checkout-div">
        {reportCart && (
          <>
            <SideButtons orientation="left">
              <Back />
              <Delete cartSubject={currentReportCart} />
            </SideButtons>
            <MainPanel
              reportCartSubject={currentReportCart}
              pdfRef={downloadButtonRef}
            />
            <SideButtons orientation="right">
              <Send
                isPdfSizeValid={isPdfSizeValid}
                sendEmailCallback={sendReportViaEmail}
                disabled={!!loadingPdf}
                loading={loadingPdf === "email"}
                loadingProgress={progressPdf}
              />
              <Download
                onClick={createPdf}
                disabled={!!loadingPdf}
                loading={loadingPdf === "download"}
                loadingProgress={progressPdf}
              />
            </SideButtons>
          </>
        )}
      </div>
      <Alert alertNotificationSubject={alertSubject} />
    </div>
  );
};

export default AppLayout;
