import {
  Incident,
  InfoField,
  InfoFieldPhoto,
  InfoFieldText,
  InfoFieldTypes,
  InfoFieldUrl,
  MediaInfo,
} from "compass-commons";
import jsPDF from "jspdf";
import autoTable, { CellHookData } from "jspdf-autotable";
import MediaFileManagerService from "../services/MediaFileManagerService";
import { buildCaption } from "../helpers/captionHelper";

const centeredText = (doc, text, y) => {
  const textWidth =
    (doc.getStringUnitWidth(text) * doc.getFontSize()) /
    doc.internal.scaleFactor;
  const textOffset = (doc.internal.pageSize.width - textWidth) / 2;
  doc.text(text, textOffset, y);
};

const rightMarginText = (doc, text, y, margin = 30) => {
  const textWidth =
    (doc.getStringUnitWidth(text) * doc.getFontSize()) /
    doc.internal.scaleFactor;
  const textOffset = doc.internal.pageSize.width - (textWidth + margin);
  doc.text(text, textOffset, y);
};

const getIndexOfGenericInfo = (data, className) => {
  const rowData = data.row.raw;

  for (let index = 0; index <= rowData.length; index++) {
    if (
      // eslint-disable-next-line no-underscore-dangle
      rowData[index]?._element &&
      // eslint-disable-next-line no-underscore-dangle
      rowData[index]._element.getElementsByClassName(className).length > 0
    )
      return index;
  }

  return -1;
};

const fetchOriginalImage = async (
  body: any[],
  infoFieldPhoto: InfoFieldPhoto
) => {
  let content: MediaInfo;
  try {
    content = await MediaFileManagerService.getMediaById(infoFieldPhoto.fileId);
  } catch (error) {
    // TODO: Add image asset when there's an error fetching image
    console.error(error);
  }

  if (content && content.thumbnail) {
    // Thumbnail is used because original image may be too large and not render in PDF
    const base64Image = `data:${content?.thumbnail?.mediaType};base64,${content?.thumbnail?.content}`;
    body.push({
      mediaContent: base64Image,
      caption: buildCaption(
        {
          fileId: infoFieldPhoto.fileId,
          resourceMappingName: infoFieldPhoto.resourceMapping?.name,
          mediaTimestamp: content.mediaTimestamp,
        },
        " | "
      ),
    });
  }
};

const fetchAllImages = async (infoFields: InfoField[]) => {
  const body = [];
  const photoFieldIds = infoFields
    .filter((o) => InfoFieldTypes.PHOTO === o.infoFieldType)
    .map((o) => {
      return o as InfoFieldPhoto;
    });
  if (photoFieldIds?.length > 0) {
    await Promise.all(
      photoFieldIds.map((fieldId) => fetchOriginalImage(body, fieldId))
    );
  }

  return body;
};

const getMediaFiles = async (
  html,
  incidentDetails: Incident[],
  incrementProgress: (progress: number) => void
) => {
  const tables: HTMLCollectionOf<HTMLTableElement> =
    html.getElementsByTagName("table");
  const images = [];
  for (let i = 0; i < tables.length; i++) {
    const table = tables.item(i);
    const genericInfos = table.getElementsByClassName(
      "operation-geninfo-card__wrapper"
    );
    if (genericInfos?.length > 0) {
      for (let k = 0; k < genericInfos.length; k++) {
        const genericInfoIdentifier = genericInfos[k].getAttribute("id");
        if (genericInfoIdentifier) {
          const incidentId = genericInfoIdentifier.split("#")[0];
          const taskId = genericInfoIdentifier.split("#")[1];

          const incident = incidentDetails.find((o) => o.id === incidentId);
          if (incident) {
            const incidentStep = incident.operatorSteps.find(
              (o) => o.id === taskId
            );
            await fetchAllImages(incidentStep.genericInfoList).then((res) => {
              if (res?.length > 0) {
                images.push({
                  executionId: incident.executionId,
                  taskId,
                  medias: res,
                });
              }
            });
          }
        }
      }
    }
    incrementProgress(60 / tables.length);
  }

  return images;
};

const addGenericInfo = (
  doc: jsPDF,
  data: CellHookData,
  genericInfoIndex: number,
  incidentDetails: Incident[]
) => {
  const elementId = data.row.raw[genericInfoIndex]?._element
    .getElementsByClassName("operation-geninfo-card__wrapper")[0]
    .getAttribute("id");
  const incidentId = elementId.split("#")[0];
  const taskId = elementId.split("#")[1];
  const incident = incidentDetails.find((o) => o.id === incidentId);
  if (incident) {
    const incidentStep = incident.operatorSteps.find((o) => o.id === taskId);
    // If the cell index is 2 (the "Details" column)
    const contentIndex = getIndexOfGenericInfo(
      data,
      "operation-geninfo-card__container"
    );

    if (contentIndex === -1) return;

    const body = [];

    incidentStep.genericInfoList.forEach((info: InfoField) => {
      if (info.infoFieldType === InfoFieldTypes.TEXT) {
        const textField = info as InfoFieldText;
        body.push([
          {
            content: `${textField.label}:`,
            colSpan: 1,
            styles: {
              valign: "middle",
              fontStyle: "bold",
              fillColor: [236, 236, 236],
              borderTop: 1,
              borderLeft: 1,
              borderRight: 1,
              borderBottom: 0,
            },
          },
        ]);
        body.push([textField.text]);
      }
      if (info.infoFieldType === InfoFieldTypes.URL) {
        const urlField = info as InfoFieldUrl;
        body.push([
          {
            content: "",
            colSpan: 1,
            styles: {
              valign: "middle",
              fillColor: [236, 236, 236],
              textColor: "blue",
              cursor: "pointer",
            },
            infoFieldType: InfoFieldTypes.URL,
            textContent: `${urlField.label}:`,
            url: urlField.url,
          },
        ]);
      }
    });

    autoTable(doc, {
      body,
      startY: data.cell.y + 2,
      margin: { left: data.cell.x + 12 },
      tableWidth: "auto",
      theme: "striped",
      styles: {
        fillColor: [212, 212, 212],
      },
      didDrawCell(dataChild) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const { infoFieldType, textContent, url } = dataChild.cell
          .raw as HTMLTableCellElement;
        if (infoFieldType && InfoFieldTypes.URL === infoFieldType) {
          dataChild.cell.text = [""];
          doc.textWithLink(
            textContent,
            dataChild.cell.x,
            dataChild.cell.y + 10,
            {
              url,
            }
          );
        }
      },
    });
  }
};

export {
  addGenericInfo,
  getIndexOfGenericInfo,
  fetchAllImages,
  centeredText,
  fetchOriginalImage,
  getMediaFiles,
  rightMarginText,
};
