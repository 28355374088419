import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import "./circularProgressWithLabel.module.css";

const CircularProgressWithLabel = ({
  progress,
}: {
  progress: number;
}): JSX.Element => {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" value={progress} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span className="progress-indicator-text">{Math.round(progress)}%</span>
      </Box>
    </Box>
  );
};

export default CircularProgressWithLabel;
