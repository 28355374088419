import React from "react";
import "./compassIncidentsTable.module.css";
import { IncidentPoint } from "compass-commons";
import { format } from "date-fns";
import TableView from "../tableView/TableView";

interface CompassIncidentsTableProps {
  incidentsList: IncidentPoint[];
}

const showExperimentalFeatures = false;

const safelyFormatDate = (dateString: string) => {
  const date = new Date(dateString);

  return date ? format(date, "LLL d HH:mm:ss") : null;
};

const CompassIncidentsTable = (
  props: CompassIncidentsTableProps
): JSX.Element => {
  const { incidentsList } = props;

  const FormattedSummary = (fsProps) => {
    const { row } = fsProps;
    const divs = [];
    if (row.summary) {
      const summary = row.summary.toString();
      const summaryLines =
        summary.indexOf("\\n") > -1
          ? summary.split("\\n")
          : summary.split(". ");
      for (let i = 0; i < summaryLines.length; i += 1) {
        divs.push(<div key={i}>{summaryLines[i]}</div>);
      }
    }

    return (
      <>
        <div key={row.id} style={{ display: "inline-table" }}>
          {divs}
        </div>
      </>
    );
  };

  const TABLE_HEADER = {
    Incident: "Incident",
    Description: "Incident Description",
    Site: "Site",
    Triggered: "Triggered",
    Activations: "Activations",
    Cleared: "Cleared",
    Operator: "Operator(s)",
    Classification: "Classification",
    Summary: "Summary",
    Source: "Source",
  };

  /**
   * Large Media
   * This is used for table columns and
   * its configuration and style.
   */
  const largeMediaTable = React.useMemo(
    () => [
      {
        id: "table-view",
        Header: "Test",
        hideHeader: true,
        columns: [
          {
            Header: "ID",
            accessor: "id",
          },
          {
            Header: TABLE_HEADER.Incident,
            accessor: (row) => row.id.substring(0, 7), // TODO remove this substring when BE sends code
          },
          {
            Header: TABLE_HEADER.Description,
            accessor: (row) => (
              <div style={{ display: "flex" }}>
                {row.icon && (
                  <div>
                    <img
                      src={row.icon}
                      alt="icon"
                      style={{ width: "35px", height: "35px" }}
                    />
                  </div>
                )}
                {row.incidentDescription && (
                  <div style={{ width: "125px" }}>
                    {row.incidentDescription}
                  </div>
                )}
              </div>
            ),
          },
          {
            Header: TABLE_HEADER.Site,
            accessor: "location",
          },
          {
            Header: TABLE_HEADER.Triggered,
            accessor: (row) => {
              return safelyFormatDate(row.creationTimestamp);
            },
          },
          {
            Header: TABLE_HEADER.Activations,
            accessor: (row) => row.activationsCount + 1,
          },
          {
            Header: TABLE_HEADER.Cleared,
            accessor: (row) => {
              return safelyFormatDate(row.clearTimestamp);
            },
          },
          {
            Header: TABLE_HEADER.Operator,
            accessor: (row) => {
              return `${row.user?.firstName} ${row.user?.lastName}`;
            },
          },
          {
            Header: TABLE_HEADER.Classification,
            accessor: "category",
          },
          {
            Header: TABLE_HEADER.Summary,
            accessor: (row) => {
              return <FormattedSummary row={row} />;
            },
          },
          {
            Header: TABLE_HEADER.Source,
            accessor: "sourceName",
          },
        ].filter(
          (value) =>
            !showExperimentalFeatures &&
            value.Header !== TABLE_HEADER.Summary &&
            value.Header !== TABLE_HEADER.Classification &&
            value.Header !== TABLE_HEADER.Operator &&
            value.Header !== TABLE_HEADER.Source
        ),
      },
    ],
    []
  );

  /**
   * This array is used to hide columns. We need to use ID for detail.
   */
  const hiddenColumns = ["id"];

  return (
    <>
      {incidentsList && incidentsList.length > 0 && (
        <TableView
          data={incidentsList}
          columns={largeMediaTable}
          totalDataCount={incidentsList?.length}
          rowsPerPage={incidentsList?.length}
          hideColumns={hiddenColumns}
          isPaginated={false}
        />
      )}
    </>
  );
};

export default CompassIncidentsTable;
