import React, { useEffect, useState } from "react";
import "./mainPanelListItem.module.css";
import {
  Incident,
  IncidentPoint,
  ReportCartItem,
  ReportCartItemTypeEnum,
} from "compass-commons";
import { Divider } from "@mui/material";
import { Button } from "dms-lib";
import { ArrowDropUpRounded, CloseRounded } from "@mui/icons-material";
import CompassDetailsTable from "../../../commons/compassDetailsTable/CompassDetailsTable";
import DataExplorerService from "../../../../services/DataExplorerService";
import CompassIncidentsTable from "../../../commons/compassIncidentsTable/CompassIncidentsTable";
import StateService from "../../../../services/StateService";
import { useStateContext } from "../../../../contexts/StateContext";

interface MainPanelListItemProps {
  cartItem: ReportCartItem;
  changeItemOrderCallback: (itemPosition) => void;
  deleteItemOrderCallback: (itemPosition) => void;
}

const MainPanelListItem = (props: MainPanelListItemProps): JSX.Element => {
  const { cartItem, changeItemOrderCallback, deleteItemOrderCallback } = props;
  const [incident, setIncident] = useState<Incident>(null);
  const [criteriaData, setCriteriaData] = useState<IncidentPoint[]>([]);
  const stateService: StateService = useStateContext();
  const { incidentDetails } = stateService;
  useEffect(() => {
    if (
      cartItem?.type === ReportCartItemTypeEnum.DETAIL &&
      cartItem?.detailCriteria?.incidentId
    ) {
      DataExplorerService.getIncidentById(cartItem?.detailCriteria?.incidentId)
        .then((r) => {
          setIncident(r);
          if (!incidentDetails.value.find((o) => o.id === r.id)) {
            const details = incidentDetails.value;
            details.push(r);
            incidentDetails.next(details);
          }
        })
        .catch(() => {
          setIncident(null);
        });
    } else if (cartItem?.type === ReportCartItemTypeEnum.CRITERIA) {
      DataExplorerService.findIncidentsByCriteria(cartItem?.filteringCriteria)
        .then((r) => {
          setCriteriaData(r?.incidentList);
        })
        .catch(() => {
          setCriteriaData([]);
        });
    }
  }, [cartItem]);

  const ItemComponent = () => {
    return (
      <div className="main-panel-list-item">
        {(incident && <CompassDetailsTable incident={incident} />) ||
          (criteriaData && criteriaData.length > 0 && (
            <div>
              <CompassIncidentsTable incidentsList={criteriaData} />
            </div>
          ))}
      </div>
    );
  };

  return (
    <>
      {cartItem && (incident || (criteriaData && criteriaData.length > 0)) && (
        <div className="main-panel-list-item-div">
          {cartItem?.position != null && cartItem?.position > 0 ? (
            <>
              <Divider
                color="#AFAFAF"
                sx={{
                  borderWidth: "1px",
                }}
                id="#ignorePdf"
              />
              <div className="main-panel-list-item-arrow">
                <Button
                  variant="contained"
                  color="inherit"
                  aria-label="changeOrder"
                  onClick={() => {
                    changeItemOrderCallback(cartItem?.position);
                  }}
                  size="small"
                  icon
                >
                  <ArrowDropUpRounded fontSize="large" color="primary" />
                </Button>
                <Button
                  variant="contained"
                  color="inherit"
                  aria-label="deleteItem"
                  onClick={() => {
                    deleteItemOrderCallback(cartItem?.position);
                  }}
                  icon
                >
                  <CloseRounded color="primary" />
                </Button>
              </div>
            </>
          ) : (
            <div className="main-panel-list-item-arrow" id="#ignorePdf">
              <Button
                variant="contained"
                color="inherit"
                aria-label="deleteItem"
                onClick={() => {
                  deleteItemOrderCallback(cartItem?.position);
                }}
                icon
              >
                <CloseRounded color="primary" />
              </Button>
            </div>
          )}
          <ItemComponent />
        </div>
      )}
    </>
  );
};

export default MainPanelListItem;
