import React from "react";
import "./sideButtons.module.css";

interface SideButtonsProps {
  orientation: string;
  children?: any;
}

const SideButtons = (props: SideButtonsProps): JSX.Element => {
  const { orientation, children } = props;

  const orientationStyle = {
    alignItems:
      orientation?.toLowerCase() === "right" ? "flex-start" : "flex-end",
  };

  return (
    <div className="side-buttons-div" style={orientationStyle}>
      {children}
    </div>
  );
};

export default SideButtons;
